import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/docs-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "nicenano-v2"
    }}>{`nice!nano v2`}</h1>
    <h2 {...{
      "id": "pinout"
    }}>{`Pinout`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "63.80368098159509%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Pinout v1",
          "title": "Pinout v1",
          "src": "/static/1788ac663060fd510f4894b286cd97b1/a6d36/pinout-v2.png",
          "srcSet": ["/static/1788ac663060fd510f4894b286cd97b1/222b7/pinout-v2.png 163w", "/static/1788ac663060fd510f4894b286cd97b1/ff46a/pinout-v2.png 325w", "/static/1788ac663060fd510f4894b286cd97b1/a6d36/pinout-v2.png 650w", "/static/1788ac663060fd510f4894b286cd97b1/e548f/pinout-v2.png 975w", "/static/1788ac663060fd510f4894b286cd97b1/3c492/pinout-v2.png 1300w", "/static/1788ac663060fd510f4894b286cd97b1/e8950/pinout-v2.png 2000w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "schematic"
    }}>{`Schematic`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.576687116564415%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABxUlEQVQoz21Sy27bQAzU//9LrwUcIA/UBnrpobcWSOLUMdTIsuR9SLtLLV/FSrabACF04BIz5AypCkBUlVkxUxoCs86fpMQhcAJOiYmEWRA5Rg6BYixFZqlcsNZ2OSUW7rq3BCHEIKVdaUqZMKOWtFRkTq5RJUjTBBCTG40dTc4ZZzEimjM6uxvC4zhYnIBFRSTFEMHFMKpItfSwp1PT1sYevbWQIsTIqs4OpvtuxtWxfRu8FVYmsv3BD08QnBBXzESsMB2MvR3Dt/3x7lC/xBCIdcqZEGL0gKQXIwXOZ/WVM30IENxW5F51/dp97baPV+j7+MQzzyjOh4V8tKv65w8thiXnsmTmkkwTIwqRTtOy/HKU4jmOcXQvyPeqG+Nvut1z2TOd54goUYEuxff1Qh6H0ZrnhHeq68asTL1bRGbMKcYySP4rLy0uBipdjiidyoPqpnM3/Z8nVUUk1zR2txvathAQc0qiCv61b27NsWXmMllYzeEX4K3qpver0347K+Thb+33e3B+ftIEUGS2v9vtl+T9ZbJohgZxjbQJ48NwqBdLOcY4g66bZ1aiBFMvfJZ9DsQ8DJ4zLMzyP5WTsnwgf7jVP9lN8E9Axd1GAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Schematic v1",
          "title": "Schematic v1",
          "src": "/static/511ae101870d00c265b84f35d5f39eda/a6d36/schematic_nice_nano_v2.png",
          "srcSet": ["/static/511ae101870d00c265b84f35d5f39eda/222b7/schematic_nice_nano_v2.png 163w", "/static/511ae101870d00c265b84f35d5f39eda/ff46a/schematic_nice_nano_v2.png 325w", "/static/511ae101870d00c265b84f35d5f39eda/a6d36/schematic_nice_nano_v2.png 650w", "/static/511ae101870d00c265b84f35d5f39eda/e548f/schematic_nice_nano_v2.png 975w", "/static/511ae101870d00c265b84f35d5f39eda/3c492/schematic_nice_nano_v2.png 1300w", "/static/511ae101870d00c265b84f35d5f39eda/61100/schematic_nice_nano_v2.png 1949w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <h1 {...{
      "id": "nicenano-v1"
    }}>{`nice!nano v1`}</h1>
    <h2 {...{
      "id": "pinout-1"
    }}>{`Pinout`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53.37423312883436%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Pinout v1",
          "title": "Pinout v1",
          "src": "/static/84712f810a4567fda4dabe54bb6cb447/a6d36/pinout.png",
          "srcSet": ["/static/84712f810a4567fda4dabe54bb6cb447/222b7/pinout.png 163w", "/static/84712f810a4567fda4dabe54bb6cb447/ff46a/pinout.png 325w", "/static/84712f810a4567fda4dabe54bb6cb447/a6d36/pinout.png 650w", "/static/84712f810a4567fda4dabe54bb6cb447/e548f/pinout.png 975w", "/static/84712f810a4567fda4dabe54bb6cb447/3c492/pinout.png 1300w", "/static/84712f810a4567fda4dabe54bb6cb447/410b4/pinout.png 2102w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "to-further-clarify"
    }}>{`To further clarify:`}</h3>
    <ul>
      <li parentName="ul">{`P0.04 (AIN2) is used to read the voltage of the battery via ADC. It can't be used for any other function.`}</li>
      <li parentName="ul">{`P0.13 on VCC shuts off the power to VCC when you set it to high`}
        <ul parentName="li">
          <li parentName="ul">{`This saves on battery immensely for LEDs of all kinds that eat power even when off`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "schematic-1"
    }}>{`Schematic`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.71165644171779%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAC4jAAAuIwF4pT92AAACHklEQVQ4y22Tj27TMBCH+/7PwTMgtA0BAwSDicFAG9vERlWxNX+aOLbj2LHjDyVp0g5myYltnT/f3e9uYW2Dcw5rDLHriDFS5SmuddS1xodAP2I/Y+w/uzUQrGV/LDYip5QFSlZ45yhlTiEznHeYWhO6bjDsvEcka4xSw7631UpQJgmVzqh1TtcGFnFLbtuWIt9Q1hKpFXHk4H07AoPHSkmj9XYf0ErSWIcxOUZnxC6yYAZ6is0VD/cfyZJThLwk+XOHLAvCFF43WY9h749pv2hqTW1qrG1x5hMhHhF5xap8zsP1OcGPOQzekWRLjFYzxFuLKgt8O0bR53WhhRiSb6TCyBPgDaE7ZiUOuT//TOf8YKyqNXl6Ri2rGRhCQApB6/0OuO92584G7+AtlXnJ6suHGdhf9iHivZ/D6/MYbEPYns3ApjFUZYmtT2ELFPqQ9fX3+eVebde2c7lM/0d5fAQsCpoB+HoAJtUB+e+r0RMiIk3Rmw3Fcrm93HtYovUVapMNjw2i7L8U/bfZw40+JO097EYFy+WSOk0pb29n++LmPdnNM8R6hXP/ANu6QVUndIOH7yjUEemvi/myFQKdJATn5jOV/WTzcICr5S7kCWgrhciP6Tgawq7qF+R3l7sScW7oEuscbqtqX+zOmQE0zcW0GFVe0doLpPiKVT8wZcaUkV7JHtrXXNj2/H5R/weMe91gGj/kjj3DpxR9av4Fcp4+GNq6dkMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Schematic v1",
          "title": "Schematic v1",
          "src": "/static/724bec729cb82c3fb302249533b98589/a6d36/schematic.png",
          "srcSet": ["/static/724bec729cb82c3fb302249533b98589/222b7/schematic.png 163w", "/static/724bec729cb82c3fb302249533b98589/ff46a/schematic.png 325w", "/static/724bec729cb82c3fb302249533b98589/a6d36/schematic.png 650w", "/static/724bec729cb82c3fb302249533b98589/e548f/schematic.png 975w", "/static/724bec729cb82c3fb302249533b98589/3c492/schematic.png 1300w", "/static/724bec729cb82c3fb302249533b98589/111fd/schematic.png 1831w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      